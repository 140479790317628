<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Database Connection</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Database Connection</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="card">
        <div class="overlay" v-if="is_loading">
          <i class="fas fa-2x fa-sync-alt fa-spin"></i>
        </div>
        <div class="card-header">
          <h3 class="card-title">Detail</h3>
          <div class="card-tools">
            <router-link class="btn btn-info" :to="{name: 'DatabaseConnections'}">
              <i class="fas fa-list"></i> List
            </router-link>
          </div>
        </div>
        <div class="card-body table-responsive p-0">
          <table class="table table-striped">
            <tbody>
            <tr>
              <td><b>Connection Name</b></td>
              <td>{{ !_.isEmpty(item.connection_name) ? item.connection_name : '' }}</td>
            </tr>
            <tr>
              <td><b>Driver</b></td>
              <td>{{ !_.isEmpty(item.driver) ? item.driver : '' }}</td>
            </tr>
            <tr>
              <td><b>Host</b></td>
              <td>{{ item.host }}</td>
            </tr>
            <tr>
              <td><b>Port</b></td>
              <td>{{ item.port }}</td>
            </tr>
            <tr>
              <td><b>Database</b></td>
              <td>{{ !_.isEmpty(item.database) ? item.database : '' }}</td>
            </tr>
            <tr>
              <td><b>Username</b></td>
              <td>{{ !_.isEmpty(item.username) ? item.username : '' }}</td>
            </tr>
            <tr>
              <td><b>Status</b></td>
              <td><span class="badge bg-success">{{ (item.status) ? 'Yes' : 'No' }}</span></td>
            </tr>
            <tr>
              <td><b>Created At</b></td>
              <td>{{ !_.isEmpty(item.created_at) ? item.created_at : '' }}</td>
            </tr>
            <tr>
              <td><b>Updated At</b></td>
              <td>{{ !_.isEmpty(item.updated_at) ? item.updated_at : '' }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {DatabaseConnectionAPI} from "@/services/api";

export default {
  name: 'DatabaseConnectionShow',
  data: () => ({
    item: {},
    is_loading: false
  }),
  mounted: function () {
    this.itemShow(this.$route.params.id);
  },
  methods: {
    itemShow(id) {
      DatabaseConnectionAPI.show(id)
        .then(item => {
        this.item = item;
      });
    }
  }
}
</script>

